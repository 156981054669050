import React, { createContext, useCallback, useContext, useState } from "react";
import { useUpdateTakeback } from "../../../api/use-takebacks";
import { formatSnowDate, useCurrentLocation } from "../../../lib/utils";
import { convertWeight } from "../receive/receive";
import { useTranslation } from "react-i18next";
import { useDerivedWeights } from "./useDerivedWeights";
import { useLocalStorage } from "./utils/useLocalStorage";
import { TakebackQueueContext } from "../../../takeback-queue/TakebackQueueProvider";
import { AnimatePresence } from "framer-motion";
import { PinModal } from "./components/PinModal";

const noop = () => {};

export const WarehouseContext = createContext({});

export const SORTED_PICTURES = {
  all: "all",
  bucket2: "bucket2_no_conforming",
  bucket3a: "bucket3a_good_quality",
  bucket3b: "bucket3b_poor_quality",
  bucket3c: "bucket3c_electronic",
  bucket3d: "bucket3d_non_lego",
};

export const WarehouseProvider = ({ children }) => {
  const { t } = useTranslation();
  const { location: currentLocation } = useCurrentLocation();

  const { add } = useContext(TakebackQueueContext);

  const [showPinModal, setShowPinModal] = useState(false);
  const [onPinSuccessFn, setOnPinSuccessFn] = useState(noop);

  const [takeback, setTackback] = useState(null);
  const [packagePictures, setPackagePictures] = useState([]);
  const [contentPictures, setContentPictures] = useState([]);
  const [controlWeight, setControlWeight] = useLocalStorage("controlWeight");
  const [contentWeight, setContentWeight] = useState("");
  const [assembledWeight, setAssembledWeight] = useState("");
  const [conformingWeight, setConformingWeight] = useState("");
  const [sortedPictures, setSortedPictures] = useState({});
  const [rejectedContentsPictures, setRejectedContentsPictures] = useState([]);

  const weightConverter = convertWeight(takeback?.u_lego_weight_unit);

  const {
    actualContentWeight,
    actualConformingWeight,
    actualAssembledWeight,
    paymentWeight,
    nonConformingWeight,
  } = useDerivedWeights({
    preSortWeight: Number.parseInt(takeback?.u_lego_presort_weight),
    controlWeight,
    contentWeight,
    conformingWeight,
    assembledWeight,
    weightConverter,
  });

  const { mutateAsync: updateTakebackAsync } = useUpdateTakeback({
    timeout: 60000,
    useV2: true,
  });

  const setCurrentTakeback = (takeback) => {
    setTackback(takeback);
  };
  const addPackagePicture = (picture) => {
    setPackagePictures([...packagePictures, picture]);
  };
  const deletePackagePicture = (picture) => {
    setPackagePictures(packagePictures.filter((pic) => pic !== picture));
  };
  const addContentPicture = (picture) => {
    setContentPictures([...contentPictures, picture]);
  };
  const deleteContentPicture = (picture) => {
    setContentPictures(contentPictures.filter((pic) => pic !== picture));
  };
  const setSortedPicture = (key, picture) => {
    setSortedPictures({ ...sortedPictures, [key]: picture });
  };
  const addRejectContentsPicture = (picture) => {
    setRejectedContentsPictures([picture]);
  };
  const deleteRejectContentsPicture = () => {
    setRejectedContentsPictures([]);
  };

  const checkInPackage = useCallback(
    async (takeback) => {
      await updateTakebackAsync({
        caseId: takeback?.number,
        u_lego_received_at: formatSnowDate(new Date()),
      });
    },
    [updateTakebackAsync]
  );

  const startProcessingPackage = useCallback(
    async (takeback) => {
      const now = formatSnowDate(new Date());

      await updateTakebackAsync({
        caseId: takeback?.number,
        u_lego_received_at: takeback?.u_lego_received_at
          ? takeback?.u_lego_received_at
          : now,
        u_warehouse_started: now,
      });
    },
    [updateTakebackAsync]
  );

  const handleModalClose = () => {
    setShowPinModal(false);
    setOnPinSuccessFn(noop);
  };

  const confirmWithPin = (onPinSuccess) => {
    setOnPinSuccessFn(() => onPinSuccess);

    setShowPinModal(true);
  };

  const onPinSuccess = () => {
    onPinSuccessFn();
    handleModalClose();
  };

  const updateTakeback = ({
    rejectReason,
    isRejected = false,
    ignore412 = false,
  } = {}) => {
    const payload = {
      caseId: takeback.number,
      uom: t("scale.weight"),
      u_lego_presort_warehouse_weight: weightConverter(actualContentWeight),
      u_lego_general_nonconform_weight: weightConverter(nonConformingWeight),
      u_lego_assembled_nonconform_weight: weightConverter(
        actualAssembledWeight
      ),
      u_lego_postsort_weight: weightConverter(actualConformingWeight),
      u_lego_payment_weight: paymentWeight,
      u_lego_verified_at: !isRejected ? formatSnowDate(new Date()) : "",
      legoValue: (takeback.u_lego_incentive_ratio * paymentWeight).toFixed(2),
      currencyCode: currentLocation.country === "US" ? "USD" : "EUR",
      u_lego_warehouse_ended_at: formatSnowDate(new Date()),
    };

    if (isRejected) {
      payload.u_lego_escalated = true;
      payload.u_condition_unacceptable_reason = rejectReason;
    }

    const imagesToUpload = [];

    if (packagePictures.length > 0) {
      imagesToUpload.push({
        filename: "v2_package_images",
        images: packagePictures,
      });
    }

    if (contentPictures.length > 0) {
      imagesToUpload.push({
        filename: "content_images",
        images: contentPictures,
      });
    }

    if (rejectedContentsPictures.length > 0) {
      imagesToUpload.push({
        filename: "reject_contents_images",
        images: rejectedContentsPictures,
      });
    }

    const sortedImages = Object.keys(sortedPictures).map((key) => {
      return {
        filename: key,
        images: [sortedPictures[key]],
      };
    });

    if (sortedImages.length > 0) {
      imagesToUpload.push(...sortedImages);
    }

    add({
      imagesToUpload,
      ...payload,
    });
  };

  const clearTakeback = () => {
    setTackback(null);
    setPackagePictures([]);
    setContentPictures([]);
    setContentWeight("");
    setAssembledWeight("");
    setConformingWeight("");
    setSortedPictures({});
    setRejectedContentsPictures([]);
  };

  const sellerDisplayName = takeback
    ? `${takeback.consumer.firstName} ${takeback.consumer.lastName}`
    : "";
  const caseId = takeback ? takeback.number : "";

  return (
    <WarehouseContext.Provider
      value={{
        checkInPackage,
        startProcessingPackage,
        currentTakeback: takeback,
        sellerDisplayName,
        caseId,
        setCurrentTakeback,
        addPackagePicture,
        deletePackagePicture,
        packagePictures,
        controlWeight,
        setControlWeight,
        contentWeight,
        setContentWeight,
        contentPictures,
        addContentPicture,
        deleteContentPicture,
        assembledWeight,
        setAssembledWeight,
        conformingWeight,
        setConformingWeight,
        nonConformingWeight,
        sortedPictures,
        setSortedPicture,
        updateTakeback,
        clearTakeback,
        rejectedContentsPictures,
        deleteRejectContentsPicture,
        addRejectContentsPicture,
        confirmWithPin,
      }}
    >
      <>
        {children}
        {showPinModal && (
          <AnimatePresence>
            <PinModal
              isOpen={showPinModal}
              onClose={() => handleModalClose()}
              onPinSuccess={onPinSuccess}
              title={`Override Incomplete Processing`}
              body="Enter a pin to finish with incomplete steps."
              primaryText="Confirm"
              secondaryText="Cancel"
            />
          </AnimatePresence>
        )}
      </>
    </WarehouseContext.Provider>
  );
};
