import AuthContext from "contexts/AuthContext";
import { useEffect, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

const { REACT_APP_API } = process.env;

const fetchTakebacks = async ({ accessToken, offset }) => {
  const response = await fetch(
    `${REACT_APP_API}/takeback/retrieve-takeback?limit=5&offset=${offset}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch takebacks");
  }

  return response.json();
};

const createTakeback = async ({ username, accessToken, payload }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/create-takeback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      username,
      weight: payload.weight,
      u_lego_postsort_weight: payload.u_lego_postsort_weight,
      u_lego_value_currency: payload.u_lego_value_currency,
      uom: payload.uom,
      ...(payload.paymentType ? { paymentType: payload.paymentType } : {}),
      returnMethod: payload.returnMethod,
      store: payload.store,
      returnTypeOffered: payload.returnTypeOffered,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to create takeback");
  }

  return response.json();
};

const updateTakeback = async ({
  accessToken,
  payload,
  timeout = 120000,
  useV2 = false,
}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort({ message: "Request timed out" }),
    timeout
  );

  const { ignore412, ...patchPayload } = payload;

  const response = await fetch(
    `${REACT_APP_API}/${useV2 ? "v2/" : ""}takeback/update-takeback`,
    {
      signal: controller.signal,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(patchPayload),
    }
  );

  clearTimeout(timeoutId);

  if (!response.ok) {
    const body = await response.text();

    if (response.status === 412) {
      const isClosedCase = body === "Forbidden: Can not update closed cases";

      const errorMessage = isClosedCase
        ? `This case has already been closed. Set aside and contact Pentatonic with this information: ${payload.caseId} and the tracking number on the package`
        : "Takeback already processed and verified. If you think this is a mistake please contact support.";

      if (ignore412) {
        return {
          code: response.status,
          message: errorMessage,
        };
      }

      throw new Error(errorMessage);
    }

    throw new Error(`Failed to update takeback: ${body}`);
  }

  return response.json();
};

export const useUpdateTakeback = (options = {}) => {
  const { user, authTokens } = useContext(AuthContext);
  const username = user?.email;
  const accessToken = authTokens?.access_token;
  const { timeout, useV2, ...reactQueryOptions } = options;

  return useMutation({
    mutationKey: ["update-takebacks", username, accessToken],
    mutationFn: (payload) =>
      updateTakeback({
        accessToken,
        payload,
        timeout,
        useV2,
      }),
    ...reactQueryOptions,
  });
};

export const useCreateTakeback = ({ onCreate = () => {} } = {}) => {
  const { user, authTokens } = useContext(AuthContext);
  const { t } = useTranslation();
  const username = user.email;
  const accessToken = authTokens?.access_token;

  useEffect(() => {
    if (window.localStorage.getItem("hasCreatedTakeback")) {
      window.localStorage.removeItem("hasCreatedTakeback");
      if (window.location.pathname !== t("urls.my_trade_ins")) {
        window.location.assign(
          `${t("urls.my_trade_ins")}${
            window.location.search ? `${window.location.search}&` : `?`
          }redirectedFrom=${encodeURIComponent(window.location.href)}`
        );
      }
    }
  }, [t]);

  return useMutation({
    mutationKey: ["create-takebacks", username, accessToken],
    mutationFn: async (payload) => {
      window.localStorage.setItem(`hasCreatedTakeback`, "true");

      return createTakeback({
        username,
        accessToken,
        payload,
      });
    },
    onSuccess: onCreate,
  });
};

export const useMyTakebacks = ({ offset = 0 } = {}) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  const response = useQuery(
    ["takebacks", accessToken, offset],
    () =>
      fetchTakebacks({
        accessToken,
        offset,
      }),
    {
      enabled: !!accessToken,
    }
  );

  return {
    ...response,
    data: response.data?.items,
    totalCount: response.data?.totalCount,
  };
};

const fetchTakebackById = async ({ id, accessToken, setTakeback }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  setTakeback(await response.json());
};

export const fetchTakebacksByTracking = async ({
  accessToken,
  upsTracking,
  dhlTracking,
  timeout = 60000,
}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort({ message: "Request timed out" }),
    timeout
  );

  const params = new URLSearchParams();
  if (upsTracking) params.append("ups_tracking", upsTracking);
  if (dhlTracking) params.append("dhl_tracking", dhlTracking);

  const response = await fetch(
    `${REACT_APP_API}/warehouse/get-mail-in?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    }
  );

  clearTimeout(timeoutId);

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  return response.json();
};

const fetchTakebacksByShipping = async ({
  accessToken,
  shippingCode,
  timeout = 60000,
}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort({ message: "Request timed out" }),
    timeout
  );

  const response = await fetch(`${REACT_APP_API}/shipping/${shippingCode}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    signal: controller.signal,
  });

  clearTimeout(timeoutId);

  if (!response.ok) {
    throw new Error("Failed to get shipping");
  }

  return response.json();
};

export const useTakebackByShippingTracking = ({
  upsTracking,
  dhlTracking,
  shippingCode,
  onSuccess,
}) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery(
    ["takebackByTracking", upsTracking, dhlTracking, shippingCode],
    () => {
      if (shippingCode) {
        return fetchTakebacksByShipping({
          accessToken,
          shippingCode,
        });
      }
      return fetchTakebacksByTracking({
        accessToken,
        upsTracking,
        dhlTracking,
      });
    },
    {
      enabled: Boolean(
        accessToken && (shippingCode || upsTracking || dhlTracking)
      ),
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
};

export const useTakebackByTrackingLazy = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return ({ upsTracking, dhlTracking, shippingCode }) => {
    if (shippingCode) {
      return fetchTakebacksByShipping({
        accessToken,
        shippingCode,
        timeout: 20000,
      });
    }
    return fetchTakebacksByTracking({
      accessToken,
      upsTracking,
      dhlTracking,
      timeout: 20000,
    });
  };
};

const fetchTakebacksByEmail = async ({ email, accessToken, setTakebacks }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/${email}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  setTakebacks(await response.json());
};

export const useTakebackById = ({ id }) => {
  const [takeback, setTakeback] = useState();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  useEffect(() => {
    fetchTakebackById({ id, accessToken, setTakeback });
  }, [accessToken, id]);

  return takeback?.[0];
};

export const useTakebacksByEmail = ({ email }) => {
  const [takebacks, setTakebacks] = useState();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  useEffect(() => {
    fetchTakebacksByEmail({ email, accessToken, setTakebacks });
  }, [accessToken, email]);

  return takebacks;
};
