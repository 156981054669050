import React, { useContext } from "react";
import AuthContext from "contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { useCurrentLocation, getQueryParams } from "lib/utils";
import { Trans, useTranslation } from "react-i18next";

import "material-symbols/outlined.css";

import { Header } from "components/header";
import { Footer } from "components/footer/footer";
import { Button } from "components/button";
import { FAQAccordion } from "components/faqs/faqs";

//query params
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";

//assets
import BgLandingHero from "assets/landingHero.png";
import BgLandingHeroEuro from "assets/landingHeroEuro.png";
import BgAccepted from "assets/landingAccepted.png";
import BgNotAccepted from "assets/landingNotAccepted.png";
import BgBricks from "assets/landingBricks.png";
import { TradeInIcon } from "pages/svgs/tradein-logo";
// import logo from "assets/logo.jpg";
import useIsMobile from "hooks/useIsMobile";

export const EndPilotLanding = () => {
  // translation
  const { t } = useTranslation();
  // const { i18n } = useTranslation();

  // auth
  const { user } = useContext(AuthContext);

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  const end_pilot_faqs_us = [
    {
      id: "item-1",
      question: t("end_landing.faqs.questions.item1.question"),
      answer: t("end_landing.faqs.questions.item1.answer"),
    },
    {
      id: "item-2",
      question: t("end_landing.faqs.questions.item2.question"),
      answer: t("end_landing.faqs.questions.item2.answer"),
    },
    {
      id: "item-3",
      question: t("end_landing.faqs.questions.item3.question"),
      answer: t("end_landing.faqs.questions.item3.answer"),
    },
    {
      id: "item-4",
      question: t("end_landing.faqs.questions.item4.question"),
      answer: t("end_landing.faqs.questions.item4.answer"),
    },
    {
      id: "item-5",
      question: t("end_landing.faqs.questions.item5.question"),
      answer: (
        <Trans
          i18nKey={"end_landing.faqs.questions.item5.answer"}
          components={{
            1: (
              <Link
                to="https://www.lego.com/en-us/service/contact-us"
                className="cursor-pointer underline text-blue"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      ),
    },

    {
      id: "item-7",
      question: t("end_landing.faqs.questions.item7.question"),
      answer: (
        <Trans
          i18nKey={"end_landing.faqs.questions.item7.answer"}
          components={{
            1: (
              <Link
                to="https://www.tangocard.com/contact-us"
                className="cursor-pointer underline text-blue"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      ),
    },
    {
      id: "item-8",
      question: t("end_landing.faqs.questions.item8.question"),
      answer: t("end_landing.faqs.questions.item8.answer"),
    },
  ];

  const end_pilot_faqs_de = [
    {
      id: "item-1",
      question: t("end_landing.faqs.questions.item1.question"),
      answer: t("end_landing.faqs.questions.item1.answer"),
    },
    {
      id: "item-2",
      question: t("end_landing.faqs.questions.item2.question"),
      answer: t("end_landing.faqs.questions.item2.answer"),
    },
    {
      id: "item-3",
      question: t("end_landing.faqs.questions.item3.question"),
      answer: t("end_landing.faqs.questions.item3.answer"),
    },
    {
      id: "item-4",
      question: t("end_landing.faqs.questions.item4.question"),
      answer: t("end_landing.faqs.questions.item4.answer"),
    },
    {
      id: "item-5",
      question: t("end_landing.faqs.questions.item5.question"),
      answer: (
        <Trans
          i18nKey={"end_landing.faqs.questions.item5.answer"}
          components={{
            1: (
              <Link
                to="https://www.lego.com/de-de/service/contact-us"
                className="cursor-pointer underline text-blue"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      ),
    },

    {
      id: "item-6",
      question: t("end_landing.faqs.questions.item6.question"),
      answer: (
        <Trans
          i18nKey={"end_landing.faqs.questions.item6.answer"}
          components={{
            1: (
              <a
                href="mailto:support@leogbricktakeback.com "
                className="cursor-pointer underline text-blue"
              >
                Support@LEGOBrickTakeBack.com
              </a>
            ),
          }}
        />
      ),
    },
    {
      id: "item-8",
      question: t("end_landing.faqs.questions.item8.question"),
      answer: t("end_landing.faqs.questions.item8.answer"),
    },
  ];

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={!user ? `${t("urls.login")}` : `${t("urls.my_trade_ins")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {!user
          ? t("end_landing.button_sign_in")
          : t("landing.button_my_trade_ins")}
      </Button>
    );
  };

  const moveTo = (elementId, offset = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  const handleScrollTo = (e, elementId, offset) => {
    e.preventDefault();
    moveTo(elementId, offset);
  };

  const isMobile = useIsMobile();
  const navigationLink = `${t("urls.my_trade_ins")}/${location.search}`;

  const mobileContent = (
    <>
      <Link to={navigationLink}>
        <TradeInIcon />
      </Link>
    </>
  );

  return (
    <div>
      <Header
        mobileChildren={mobileContent}
        classNames="bg-yellow border-none"
        showSupport={Boolean(!user)}
      >
        <div className="flex gap-large">
          {!isMobile && (
            <Button
              href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
              variant="secondary"
              queryParams={queryParams}
            >
              {user
                ? t("landing.button_my_trade_ins")
                : t("landing.button_sign_in")}
            </Button>
          )}
        </div>
      </Header>

      <div
        id="hero"
        className="bg-gradient-to-b from-[#FFF087] via-[#FFE950] to-[#FFE21B] px-x-large md:px-xxxx-large relative"
      >
        <div className="bg-white/50 p-small text-center text-xs absolute bottom-0 left-0 right-0 z-20">
          <Trans
            i18nKey={"landing.pentatonic"}
            components={{
              1: (
                <Link
                  to={t("urls.terms_and_conditions")}
                  className="cursor-pointer text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </div>
        <div className="container mx-auto flex flex-col md:flex-row md:items-end gap-xx-large max-w-full md:max-w-6xl relative z-10 pb-xxx-large md:pb-large">
          <div className="mx-auto md:mx-0 mt-xx-large md:mt-xxxx-large max-w-2xl mb-xx-large md:mb-[200px] lg:mb-xxxx-large">
            {/* {!user && (
              <img
                src={logo}
                alt="LEGO Logo"
                className="w-[40px] h-[40px] md:w-[55px] md:h-[55px] mb-xx-large"
              />
            )} */}
            <h1 className="text-primary font-bold text-[3rem] leading-[3.875rem] md:text-7xl lg:text-8xl mb-large">
              <Trans i18nKey={"end_landing.hero.heading"} />
            </h1>
            <p className="text-secondary text-2xl mb-large pr-0 md:pr-56 lg:pr-0">
              <Trans i18nKey={"end_landing.hero.sub_heading_1"} />
            </p>

            <p className="text-secondary text-2xl pr-0 md:pr-56 lg:pr-0">
              {" "}
              {t("end_landing.hero.sub_heading_2")}{" "}
              {/* {!user && (
                <Trans
                  i18nKey={"end_landing.hero.sub_heading_3"}
                  components={{
                    1: (
                      <Link
                        to={t("urls.login")}
                        className="cursor-pointer text-blue"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              )} */}
            </p>
            <div className="flex flex-col items-stretch  mt-xx-large md:items-center md:flex-row space-y-large md:space-y-0 md:space-x-x-large max-w-screen min-w-0">
              <CtaButton className="" />
              <Button
                href="#"
                onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                variant="secondary"
                icon="arrow_downward"
                className=""
              >
                {t("end_landing.hero.secondary_btn")}
              </Button>
            </div>
          </div>
        </div>
        <img
          src={
            currentLocation.country === "US" ? BgLandingHero : BgLandingHeroEuro
          }
          alt={t("landing.hero.image_alt")}
          className="hidden md:block md:absolute md:right-0 md:bottom-0"
        />
      </div>
      <div id="whatWeAccept">
        <div className="bg-green-400 flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-xxx-large">
            {t("landing.what_we_accept.heading")}
          </h2>
          <div className="flex flex-col lg:flex-row justify-stretch mx-auto space-y-xxx-large lg:space-y-0 lg:gap-xxx-large lg:max-w-6xl">
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large">
                <img
                  src={BgAccepted}
                  alt={t("landing.what_we_accept.accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-4xl">
                  {t("landing.what_we_accept.accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  <Trans i18nKey="landing.what_we_accept.accepted_bullets.item1" />
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item3")}
                </li>
              </ul>
            </div>
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large">
                <img
                  src={BgNotAccepted}
                  alt={t("landing.what_we_accept.not_accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-4xl">
                  {t("landing.what_we_accept.not_accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item1")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item3")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item4")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item5")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="whatHappens" className="flex flex-col sm:flex-row mx-auto ">
        <div className="basis-1/2">
          <img
            src={BgBricks}
            alt="A mixture of colorful LEGO bricks"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="bg-green p-x-large md:p-xxx-large lg:p-xxxx-large basis-1/2">
          <div className="max-w-2xl">
            <h1 className="text-white text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold mb-x-large">
              {t("landing.what_happens.heading")}
            </h1>
            <p className="text-green-300 text-2xl mb-large">
              {t("landing.what_happens.body1")}
            </p>
            <p className="text-green-300 text-2xl">
              {t("landing.what_happens.body2")}
            </p>
          </div>
        </div>
      </div>

      <div id="faqs">
        <div className="flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-[30px] md:text-[40px] leading-[39px] md:leading-[52px] font-bold text-center mb-xxx-large">
            {t("landing.faqs.heading")}
          </h2>
          <div className="mx-auto w-full lg:max-w-6xl">
            <FAQAccordion
              faqs={
                currentLocation.country === "US"
                  ? end_pilot_faqs_us
                  : end_pilot_faqs_de
              }
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
